import { type FunctionComponent, useEffect, useState } from 'react'
import { createBreakpoint } from 'react-use'
import { CardRow, GridItem, Picture, TypographyV2 as Typography } from '@which/seatbelt'
import type { ImageSource } from '@which/seatbelt/src/types'

import classnames from 'classnames'

import type { CampaignsHomepageContentItem } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import styles from './CurrentCampaigns.module.scss'

const useBreakpoint = createBreakpoint({ mobile: 320, tablet: 768, desktop: 1024 })

export const CurrentCampaigns: FunctionComponent<Props> = ({ components }) => {
  const breakpointDisplay = useBreakpoint()
  const currentCollection = components.filter(({ type }) => type === 'current')

  const [breakpointLoaded, setBreakpointLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (breakpointDisplay) {
      setBreakpointLoaded(true)
    }
  }, [breakpointDisplay])

  if (!breakpointLoaded) {
    return null
  }

  const getCard: FunctionComponent<getCard> = ({ card, cardNum = 1, breakpoint = 'desktop' }) => {
    if (!card) {
      return null
    }

    const { header, standfirst, img, progressPercentage, signatureCount, url } = card.data

    return (
      <article data-testid="current-campaigns-item-card" className={styles.currentCampaign}>
        <Link href={url} appearance="secondary" includeAnimation={false}>
          <div
            className={classnames(styles.currentCampaignCard, {
              [styles.currentCampaignCardBottomCard]:
                currentCollection.length > 3 &&
                currentCollection.length < 6 &&
                (cardNum === 3 || cardNum === 5),
            })}
          >
            <Picture
              alt={img.alt}
              src={img.src}
              sources={img.sources}
              aspectRatioMain={
                currentCollection.length === 4 && cardNum === 4 ? 'four-to-three' : 'two-to-one'
              }
              lazyLoad={true}
              className={styles.currentCampaignCardImage}
            />
            <div className={styles.currentCampaignCardDetails}>
              {breakpoint !== 'desktop' && (
                <Typography tag="h3" textStyle="sb-text-body-default-strong">
                  {header}
                </Typography>
              )}

              {breakpoint === 'desktop' && (
                <Typography tag="h3" textStyle="sb-text-heading-small">
                  {header}
                </Typography>
              )}

              <Typography textStyle="sb-text-body-default-regular">{standfirst}</Typography>

              {signatureCount && (
                <>
                  <div className={styles.signatureCount}>
                    <Typography textStyle="sb-text-heading-medium">{signatureCount}</Typography>
                    <Typography textStyle="sb-text-body-default-regular">Signatures</Typography>
                  </div>
                  <progress
                    aria-label={`'progress bar at:' ${progressPercentage}%`}
                    id="file"
                    value={progressPercentage}
                    max="100"
                  >
                    {progressPercentage}
                  </progress>
                </>
              )}
            </div>
          </div>
        </Link>
      </article>
    )
  }

  const renderCards = (collection: currentCampaignCard[], breakpoint: string) =>
    collection.map((current, index) => {
      return (
        <div
          data-testid="current-campaigns-item"
          className={styles.currentCampaignItem}
          key={`${current.data.header}${index}`}
        >
          {getCard({ card: current, breakpoint: breakpoint })}
        </div>
      )
    })

  return (
    <GridItem
      id={'section-current-campaigns'}
      span={{ medium: 12, large: 12 }}
      columnStart={{ medium: 1, large: 1 }}
    >
      {breakpointDisplay !== 'desktop' && (
        <CardRow className={styles.mobileCarousel}>
          {renderCards(currentCollection, breakpointDisplay)}
        </CardRow>
      )}
      {breakpointDisplay === 'desktop' && (
        <div
          className={classnames({
            [styles.currentCardsSingle]: currentCollection.length === 1,
            [styles.currentCardsRow]: currentCollection.length > 1 && currentCollection.length < 4,
            [styles.currentCardsGrid + ' ' + styles[`grid${currentCollection.length}Cards`]]:
              currentCollection.length > 3,
          })}
          data-testid="current-campaigns"
        >
          {currentCollection.map((current, index) => (
            <div
              key={current.data.standfirst}
              className={classnames({
                [styles[`card${index + 1}`]]: currentCollection.length > 3,
              })}
            >
              {getCard({ card: current, cardNum: index + 1 })}
            </div>
          ))}
        </div>
      )}
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  components: CampaignsHomepageContentItem[]
}

type currentCampaignCard = {
  data: {
    header: string
    standfirst: string
    img: {
      src: string
      alt: string
      sources: ImageSource[]
    }
    progressPercentage: string
    signatureCount: string
    url: string
  }
}

type getCard = {
  card: currentCampaignCard
  cardNum?: number
  breakpoint?: string
}
